<template>
  <div class="datatable">
    <div class="datatable-header" v-text="contact.title"></div>
    <div class="datatable-content">
      <template v-for="item in board">
        <div class="datatable-item" :key="item.email">
          <div class="name">
            {{ item.name }}
            <span
              class="role"
              v-text="item.role ? ' - (' + item.role + ')' : ''"
            ></span>
          </div>
          <a class="email" :href="'mailto:' + item.email" target="_blank">
            {{ item.email }}
          </a>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { firebase } from "../plugins";

export default {
  name: "Contact",
  data: () => ({
    board: [],
    contact: {}
  }),
  firestore: {
    board: firebase.db.collection("board").orderBy("order"),
    contact: firebase.pagesRef.doc("contact")
  }
};
</script>

<style>
.datatable {
  color: white;
}

.datatable-header {
  text-align: center;
  font-size: 40px;
  font-weight: bold;
}

.datatable-content {
  margin: 20px auto;
  max-width: 600px;
}

.datatable-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: 10px auto;
  font-size: 15px;
}

.name {
  font-size: 20px;
}
.role {
  color: gray;
}
.email {
  color: var(--footer-color);
  font-weight: bold;
}
.email:hover {
  color: var(--bwb-color);
}

@media (max-width: 800px) {
  .datatable-item {
    margin: 10px 24px;
    flex-direction: column;
  }
}
</style>
